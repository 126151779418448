import { default as indexif8xBg25EOMeta } from "/opt/build/repo/pages/about/index.vue?macro=true";
import { default as _91slug_932BH3UhQjmqMeta } from "/opt/build/repo/pages/blog/[slug].vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91slug_93nC2thKjNz5Meta } from "/opt/build/repo/pages/note/[slug].vue?macro=true";
import { default as _91slug_936iyl4kDgLQMeta } from "/opt/build/repo/pages/portfolio/[slug].vue?macro=true";
import { default as indexTK2EnYQ9t7Meta } from "/opt/build/repo/pages/portfolio/index.vue?macro=true";
export default [
  {
    name: "about___",
    path: "//about",
    component: () => import("/opt/build/repo/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/about",
    component: () => import("/opt/build/repo/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("/opt/build/repo/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___it",
    path: "/it/about",
    component: () => import("/opt/build/repo/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___",
    path: "//blog/:slug()",
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug()",
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug()",
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___it",
    path: "/it/blog/:slug()",
    component: () => import("/opt/build/repo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___",
    path: "//blog",
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___it",
    path: "/it/blog",
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "index___",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "note-slug___",
    path: "//note/:slug()",
    meta: _91slug_93nC2thKjNz5Meta || {},
    component: () => import("/opt/build/repo/pages/note/[slug].vue").then(m => m.default || m)
  },
  {
    name: "note-slug___en",
    path: "/note/:slug()",
    meta: _91slug_93nC2thKjNz5Meta || {},
    component: () => import("/opt/build/repo/pages/note/[slug].vue").then(m => m.default || m)
  },
  {
    name: "note-slug___es",
    path: "/es/note/:slug()",
    meta: _91slug_93nC2thKjNz5Meta || {},
    component: () => import("/opt/build/repo/pages/note/[slug].vue").then(m => m.default || m)
  },
  {
    name: "note-slug___it",
    path: "/it/note/:slug()",
    meta: _91slug_93nC2thKjNz5Meta || {},
    component: () => import("/opt/build/repo/pages/note/[slug].vue").then(m => m.default || m)
  },
  {
    name: "portfolio-slug___",
    path: "//portfolio/:slug()",
    component: () => import("/opt/build/repo/pages/portfolio/[slug].vue").then(m => m.default || m)
  },
  {
    name: "portfolio-slug___en",
    path: "/portfolio/:slug()",
    component: () => import("/opt/build/repo/pages/portfolio/[slug].vue").then(m => m.default || m)
  },
  {
    name: "portfolio-slug___es",
    path: "/es/portfolio/:slug()",
    component: () => import("/opt/build/repo/pages/portfolio/[slug].vue").then(m => m.default || m)
  },
  {
    name: "portfolio-slug___it",
    path: "/it/portfolio/:slug()",
    component: () => import("/opt/build/repo/pages/portfolio/[slug].vue").then(m => m.default || m)
  },
  {
    name: "portfolio___",
    path: "//portfolio",
    component: () => import("/opt/build/repo/pages/portfolio/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio___en",
    path: "/portfolio",
    component: () => import("/opt/build/repo/pages/portfolio/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio___es",
    path: "/es/portfolio",
    component: () => import("/opt/build/repo/pages/portfolio/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio___it",
    path: "/it/portfolio",
    component: () => import("/opt/build/repo/pages/portfolio/index.vue").then(m => m.default || m)
  }
]