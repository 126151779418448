import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KlVwwuJRCL from "/opt/build/repo/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/opt/build/repo/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import binary_control_iEPKQnK3ci from "/opt/build/repo/plugins/binary-control.ts";
import console_writes_client_JW60F7ShUM from "/opt/build/repo/plugins/console-writes.client.ts";
import content_by_name_6W0T5uXcbm from "/opt/build/repo/plugins/content-by-name.ts";
import directives_8CcCirWtnE from "/opt/build/repo/plugins/directives.ts";
import go_back_24uUSLl0Xb from "/opt/build/repo/plugins/go-back.ts";
import image_validation_8v5HksfWDL from "/opt/build/repo/plugins/image-validation.ts";
import language_case_Pt4ECLsuEw from "/opt/build/repo/plugins/language-case.ts";
import no_scroll_client_91sEiSbfc7 from "/opt/build/repo/plugins/no-scroll.client.ts";
import range_items_vZFtIZTLa2 from "/opt/build/repo/plugins/range-items.ts";
import scroll_to_smoothly_client_tWtc6VRdPE from "/opt/build/repo/plugins/scroll-to-smoothly.client.ts";
import theme_color_Jaiqzuv8Z5 from "/opt/build/repo/plugins/theme-color.ts";
import theme_zupea9J243 from "/opt/build/repo/plugins/theme.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_KlVwwuJRCL,
  plugin_t2GMTTFnMT,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  binary_control_iEPKQnK3ci,
  console_writes_client_JW60F7ShUM,
  content_by_name_6W0T5uXcbm,
  directives_8CcCirWtnE,
  go_back_24uUSLl0Xb,
  image_validation_8v5HksfWDL,
  language_case_Pt4ECLsuEw,
  no_scroll_client_91sEiSbfc7,
  range_items_vZFtIZTLa2,
  scroll_to_smoothly_client_tWtc6VRdPE,
  theme_color_Jaiqzuv8Z5,
  theme_zupea9J243
]