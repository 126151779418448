import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPost, LazyPostTeaser, LazyPostsList, LazyContainer, LazyDetail, LazyIcon, LazyImage, LazyInput, LazyLogo, LazyMedia, LazyModal, LazyNote, LazyPage, LazyRoute, LazyTextContent, LazyBlank, LazyFooter, LazyHeader, LazyMain, LazyTranslate, LazyProject, LazyProjectSlider, LazyProjectTeaser, LazyProjectsList } from '#components'
const lazyGlobalComponents = [
  ["Post", LazyPost],
["PostTeaser", LazyPostTeaser],
["PostsList", LazyPostsList],
["Container", LazyContainer],
["Detail", LazyDetail],
["Icon", LazyIcon],
["Image", LazyImage],
["Input", LazyInput],
["Logo", LazyLogo],
["Media", LazyMedia],
["Modal", LazyModal],
["Note", LazyNote],
["Page", LazyPage],
["Route", LazyRoute],
["TextContent", LazyTextContent],
["Blank", LazyBlank],
["Footer", LazyFooter],
["Header", LazyHeader],
["Main", LazyMain],
["Translate", LazyTranslate],
["Project", LazyProject],
["ProjectSlider", LazyProjectSlider],
["ProjectTeaser", LazyProjectTeaser],
["ProjectsList", LazyProjectsList],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
